//ABOUT, SERVICES, CONTACTS, CUSTOM_PAGES, PROJECT, STATIC_TEXTS, FILES, LANGUAGES, SUBSCRIBERS, PARTNERS, MEMBERS, BLOGS
//, , , CUSTOM_PAGES, PROJECT, , , , , , MEMBERS, BLOGS


export const ACCESS_PAGES = {
    moderators: {
        key: 'moderator',
        name: 'Մոդերատորներ',
        icon: 'supervised_user_circle',
        crud: false,
        isHidden: true,
    },
    home: {
        key: 'home',
        name: 'Գլխավոր',
        icon: 'home',
        crud: true,
        isHidden: true,
    },
    media: {
        key: 'file',
        name: 'Ֆայլեր',
        icon: 'filter',
        crud: true,
        isHidden: false,
    },
    services: {
        key: 'service',
        name: 'Ծառայություններ',
        icon: 'settings',
        crud: true,
        isHidden: false,
    },
    news: {
        key: 'NEWS',
        name: 'Նորություններ',
        icon: 'receipt',
        crud: true,
        isHidden: false,
    },
    partners: {
        key: 'partner',
        name: 'Գործընկերներ',
        icon: 'group_work',
        crud: true,
        isHidden: false,
    },
    // products: {
    //     key: 'PRODUCT',
    //     name: 'Ապրանքեր',
    //     icon: 'add_shopping_cart',
    //     crud: true,
    //     isHidden: false,
    // },
    about: {
        key: 'about',
        name: 'Մեր Մասին',
        icon: 'category',
        crud: true,
        isHidden: false,
    },
    info: {
        key: 'program',
        name: 'Տեղեկատվություն',
        icon: 'info',
        crud: true,
        isHidden: false,
    },
    // subscribers: {
    //     key: 'SUBSCRIBERS',
    //     name: 'Բաժանորդներ',
    //     icon: 'supervisor_account',
    //     crud: true,
    //     isHidden: false,
    // },
    // requests: {
    //     key: 'CALL_REQUESTS',
    //     name: 'Զանգի Հայտեր',
    //     icon: 'folder_shared',
    //     crud: true,
    //     isHidden: false,
    // },
    // "order-requests": {
    //     key: 'ORDER_REQUESTS',
    //     name: 'Պատվերի Հայտեր',
    //     icon: 'grading',
    //     crud: true,
    //     isHidden: false,
    // },
    languages: {
        key: 'language',
        name: 'Լեզուներ',
        icon: 'language',
        crud: true,
        isHidden: false,
    },
    contact: {
        key: 'contact',
        name: 'Կապ',
        icon: 'contact_phone',
        crud: true,
        isHidden: false,
    },
    staticTexts: {
        key: 'static-texts',
        name: 'Թարգմանություններ',
        icon: 'text_format',
        crud: true,
        isHidden: false,
    },
};
