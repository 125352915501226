export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - services",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - info",
                key: "header_item_info"
            },
            {
                label: "Header - contact",
                key: "header_item_contact"
            },
        ]
    },
    buttons: {
        pageName: 'Buttons',
        list: [
            {
                label: "Button - view us",
                key: "btn_view_us"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - main block title",
                key: "homepage_main_block_title"
            },
            {
                label: "Homepage - block sub title",
                key: "homepage_block_sub_title"
            },
            {
                label: "Homepage - more service",
                key: "homepage_more_service"
            },
            {
                label: "Homepage - info btn",
                key: "homepage_more_info_btn"
            },
            {
                label: "Homepage - services title",
                key: "homepage_services_title"
            },
            {
                label: "Homepage - services block1 title",
                key: "homepage_services_block1_title"
            },
            {
                label: "Homepage - services block1 description",
                key: "homepage_services_block1_description"
            },
            {
                label: "Homepage - services block2 title",
                key: "homepage_services_block2_title"
            },
            {
                label: "Homepage - services block2 description",
                key: "homepage_services_block2_description"
            },
            {
                label: "Homepage - partners list",
                key: "homepage_partners_list"
            },
            {
                label: "Homepage - contact title",
                key: "homepage_contact_title"
            },
            {
                label: "Homepage - contact btn",
                key: "homepage_contact_btn"
            },
            {
                label: "Homepage - contact placeholder email",
                key: "homepage_contact_placeholder_email"
            }
        ]
    },
    services:{
        pageName:"Services",
        list:[
            {
                label:"Service - sub title",
                key:"service_sub_title"
            },
            {
                label:"Service - partners description",
                key:"service_partners_description"
            },
        ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section sites title",
                key: "footer_section_sites_title"
            },
            {
                label: "Footer - section home",
                key: "footer_section_home"
            },
            {
                label: "Footer - section services",
                key: "footer_section_services"
            },
            {
                label: "Footer - services 1",
                key: "footer_services_1"
            },
            {
                label: "Footer - services 2",
                key: "footer_services_2"
            },
            {
                label: "Footer - section about",
                key: "footer_section_about"
            },
            {
                label: "Footer - section info",
                key: "footer_section_info"
            },
            {
                label: "Footer - section staff",
                key: "footer_section_staff"
            },
            {
                label: "Footer - section contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - copyright",
                key: "footer_copyright"
            },
            {
                label: "Footer - section developed",
                key: "footer_section_developed_by_text"
            },
            {
                label: "Footer - presentation",
                key: "footer_section_button_presentation"
            },
        ]
    },
    about: {
        pageName: 'About',
        list: [
            {
                label: "About - sub title",
                key: "about_sub_title"
            },
            {
                label: "About - block 1 sub title",
                key: "about_block1_sub_title"
            },
        ]
    },
    info: {
        pageName: 'Info',
        list: [
            {
                label: "Info - page sub title",
                key: "info_sub_title"
            },
            {
                label: "Info - block 2 pdf title",
                key: "info_block2_pdf_title"
            },
            {
                label: "Info - block 2 pdf btn",
                key: "info_block2_pdf_btn"
            },
            {
                label: "Info - block 2 download part title",
                key: "info_block2_download_part_title"
            },
            {
                label: "Info - block 2 download btn 1",
                key: "info_block2_download_btn1"
            },
            {
                label: "Info - block 2 download btn 2",
                key: "info_block2_download_btn2"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - title",
                key: "contact_title"
            },
            {
                label: "Contacts - description",
                key: "contact_description"
            },
            {
                label: "Contacts - email title",
                key: "contact_email_title"
            },
            {
                label: "Contacts - email description",
                key: "contact_email_desc"
            },
            {
                label: "Contacts - number title",
                key: "contact_number_title"
            },
            {
                label: "Contacts - number description",
                key: "contact_number_desc"
            },
            {
                label: "Contacts - address title",
                key: "contact_address_title"
            },
            {
                label: "Contacts - address description",
                key: "contact_address_desc"
            },
            {
                label: "Contacts - service",
                key: "contacts_page_services"
            },
            {
                label: "Contacts - service physical",
                key: "contacts_page_service_physical"
            },
            {
                label: "Contacts - service person",
                key: "contacts_page_service_person"
            },
            {
                label: "Contacts - service information",
                key: "contacts_page_service_information"
            },
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
        ]
    },
    news: {
        pageName: 'News',
        list: [
            {
                label: "News - header title",
                key: "news_header_title"
            },
            {
                label: "News - title",
                key: "news_title"
            },
            {
                label: "News - description",
                key: "news_description"
            },
            {
                label: "News - subTitle",
                key: "news_subTitle"
            },
            {
                label: "News - link",
                key: "news_link"
            },
            {
                label: "News - pdf",
                key: "news_pdf"
            },
        ]
    },
}
